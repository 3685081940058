import loadable from "@loadable/component";
import i18n from "i18next";
import React, {type FC, memo, useCallback, useEffect, useMemo, useState} from "react";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useMatch} from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import Api from "./Api";
import Admin from "./components/Admin";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Loader from "./components/Loader";
import ScrollTop from "./components/ScrollTop";

import "./custom.css";
import {type IRoute, routes, user} from "./dataSlice";
import {type RootState} from "./Store";
import LanguageSwitcher from "./components/LanguageSwitcher";
import {Box} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import {theme} from "./theme";

interface IProps {
}

const App: FC<IProps> = memo(() => {
    const [cookie, setCookie] = useCookies(["preferredLanguage"]);
    const [language, setLanguage] = useState<string>(cookie.preferredLanguage ?? "en");
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
    const dispatch = useDispatch();
    const isAdminPage = useMatch("/admin");
    const state = useSelector((state: RootState) => state.data);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useCallback(() => window.scrollTo({top: 0, behavior: "smooth"}), []);

    useEffect(() => {
        const fetchRoutes = async () => {
            const response = await fetch(
                `./data/routes.json?t=${new Date().getTime()}`
            );
            const data = await response.json();
            dispatch(routes(data));
        };

        fetchRoutes();
    }, [dispatch]);

    useEffect(() => {
        setCookie("preferredLanguage", language, {path: "/"});
        (async () => await i18n.changeLanguage(language))();
    }, [language, setCookie]);

    useAsyncEffect(
        async () => {
            const response = await Api.user();
            dispatch(user(response));
        },
        [dispatch]
    );

    const header = useMemo(() => isAdminPage == null && <Header/>, [isAdminPage]);
    const footer = useMemo(() => isAdminPage == null && <Footer/>, [isAdminPage]);

    const LoadablePage = loadable(async (props: any) => await import(`./components/${props.page}`), {
        fallback: <div>Page is Loading...</div>,
        cacheKey: props => props.page
    });

    return <>
        <Box sx={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 999,
            padding: isMobile ? 1 : 2,
        }}>
            <LanguageSwitcher layout={"row"}/>
        </Box>
        <div id={"back-to-top-anchor"}></div>
        {header}
        <Routes>
            <Route path="/" element={<Home/>}/>
            {state.routes.map((route: IRoute, index: any) => <Route key={index} path={route.key}
                                                                    element={<LoadablePage page={route.element}/>}/>)}
            <Route path="/admin" element={<Admin/>}/>
            <Route path="/thank-you-form" element={<LoadablePage page={'Thank'}/>}/>
            <Route path="/grazie-form" element={<LoadablePage page={'Grazie'}/>}/>
        </Routes>
        {footer}
        <ScrollTop/>
        <Loader/>
    </>;
});

export default App;
